<template >
  <iframe 
  :src="`https://docs.google.com/gview?url=${file}&embedded=true`"
  class="responsive-iframe"
  style="height: 500px !important">
  </iframe>
</template>
// :src="`https://docs.google.com/gview?url=${file}&embedded=true`"
<script>
export default {
  props: ['file'],
};
</script>

